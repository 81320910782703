import React from "react";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <div className="footer-left-block">
          <h4 className="footer-title">Keep in touch</h4>
          <a className="footer-link" href="mailto:hello@techflower.io">
            hello@techflower.io
          </a>
        </div>
        <div className="footer-right-block">
          <h4 className="footer-title">Follow us:</h4>

          <a
            className="footer-social-link"
            href="https://www.facebook.com/techflower.ukraine"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            className="footer-social-link"
            href="https://www.linkedin.com/company/techflower-ukraine/"
            target="_blank"
            rel="noreferrer"
          >
            Linkedin
          </a>
        </div>
      </footer>

      {/* --- STYLES --- */}
      <style jsx>{`
        .footer-title {
          font-family: Steradian;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.01em;
          color: #a9a9bf;
        }

        .footer-link,
        .footer-social-link {
          font-family: Steradian;
          font-style: normal;
          font-weight: 500;
          line-height: 1.4;
          letter-spacing: -0.01em;
          color: #141418;
          transition: color 0.5s ease-in-out;

          &:hover {
            color: #0005fb;
          }
        }
        .footer-social-link:not(:last-child) {
          margin-right: 25px;
        }

        @media screen and (min-width: 320px) {
          .footer {
            display: flex;
            padding: 0 10px;
            margin: 0 auto 14px;
            flex-direction: column;
            justify-content: space-between;
          }
          .footer-link,
          .footer-social-link {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 1080px) {
          .footer-left-block {
            margin-right: auto;
            margin-bottom: 12px;
          }
          .footer-right-block {
            margin-left: auto;
          }
          .footer-title {
            font-size: 14px;
          }
        }
        @media screen and (min-width: 1080px) {
          .footer {
            margin: 0 auto 51px;
            align-items: center;
            flex-direction: row;
            padding: 0 70px 0 160px;
            justify-content: space-between;
          }
          .footer-title {
            font-size: 18px;
            margin-bottom: 26px;
          }
          .footer-link,
          .footer-social-link {
            font-size: 18px;
          }
        }
        @media screen and (min-width: 1300px) {
          .footer {
            padding: 0 130px 0 220px;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { Footer };
