import React from "react";
import PropTypes from "prop-types";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

import "./index.css";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Header />
      <main>{children}</main>

      <Footer />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.object.isRequired
};

export default Layout;
