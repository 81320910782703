// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-js": () => import("./../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-paginated-blog-js": () => import("./../../src/templates/PaginatedBlog.js" /* webpackChunkName: "component---src-templates-paginated-blog-js" */),
  "component---src-templates-post-template-js": () => import("./../../src/templates/PostTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

