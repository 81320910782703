import React from "react";
import { Link } from "gatsby";
import { Menu } from "../Menu";
import TechflowerSVG from "../../images/svg-icons/techflower.svg";

const styledLink = {
  display: "flex",
  alignItems: "center"
};

const Header = () => {
  return (
    <React.Fragment>
      <header className="header">
        <Link to="/" className="logoType" style={styledLink}>
          <div className="logo">
            <TechflowerSVG />
          </div>
        </Link>
        <Menu />
      </header>

      {/* --- STYLES --- */}
      <style jsx>{`
        .header {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
        }
        @media screen and (min-width: 320px) {
          .header {
            padding: 10px 15px 0;
            flex-direction: column;
          }
          :global(.logoType) {
            margin-bottom: 15px;
          }
        }
        @media screen and (min-width: 1200px) {
          .header {
            padding: 40px 70px 0;
            flex-direction: row;
          }
        }
        @media screen and (min-width: 1300px) {
          .header {
            padding: 60px 130px 0;
          }
        }

        .type {
          margin-right: 8px;
        }
      `}</style>
    </React.Fragment>
  );
};

export { Header };
