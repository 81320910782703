import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const activeStyleLink = {
  color: "#0005fb"
};

const Menu = () => {
  return (
    <React.Fragment>
      <nav className="menu" rel="js-menu">
        <ul className="ul-list">
          <li className="menu-item">
            <AnchorLink stripHash to="/#aboutUs" className="link" title="About Us">
              About Us
            </AnchorLink>
          </li>
          <li className="menu-item">
            <AnchorLink stripHash to="/#weOffer" className="link" title="Services">
              Services
            </AnchorLink>
          </li>
          <li className="menu-item">
            <Link className="link" to="/blog/" activeStyle={activeStyleLink}>
              Blog
            </Link>
          </li>
          <li className="menu-item">
            <AnchorLink stripHash className="link" to="/#contactUs" title="Contact Us">
              Contact Us
            </AnchorLink>
          </li>
        </ul>
      </nav>

      {/* --- STYLES --- */}
      <style jsx>{`
        .menu {
          .ul-list {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .menu-item:not(:first-child) {
            margin-left: 50px;
          }
        }
        .menu :global(.link) {
          font-family: Steradian;
          font-style: normal;
          font-weight: normal;
          line-height: 1.4;
          letter-spacing: -0.01em;
          color: #141418;
          font-size: 18px;
          transition: color 0.5s ease-in-out;
        }
        .menu :global(.link:hover) {
          color: #0005fb;
        }
        @media screen and (min-width: 320px) {
          .menu {
            .menu-item:not(:first-child) {
              margin-left: 15px;
            }
          }
        }

        @media screen and (min-width: 1200px) {
          .menu {
            .menu-item:not(:first-child) {
              margin-left: 50px;
            }
          }
        }
      `}</style>
    </React.Fragment>
  );
};

export { Menu };
